

import router from "./index"

// 复原滚动条位置
router.beforeEach((to, from, next) => {
   let dom = document.getElementsByTagName("html")[0]
   dom.scrollTop = 0;
   if (dom !== null || dom.scrollTop == 0) {
      next()
   }
});

export default router;