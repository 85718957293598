
import axios from "axios";
let baseURL
let env = process.env.NODE_ENV;
switch (env) {
   case "development":
      baseURL = Global.Development
      break;
   case "production":
      baseURL = Global.Production
      break
   case "test":
      baseURL = Global.Test
}
axios.defaults.baseURL = baseURL
axios.create({
   timeout: 3000,
   withCredentials: true,
})
export default axios
