import Vue from "vue";
import App from "./App.vue";
import router from "./router/init";

import axios from "./api/config";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import MuseUI from "muse-ui";
import "muse-ui/dist/muse-ui.css";
Vue.use(MuseUI);

Vue.config.productionTip = false;

import "./assets/style/base.scss";
import "./assets/util/html-fontsize";
import { i18n } from "./lang";
// element-ui组件国际化
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
