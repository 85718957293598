<template>
<div id="app">
   <router-view v-if="isRouterAlive" />
</div>
</template>

<script>
export default {
   provide() {
      return {
         reload: this.reload
      };
   },
   data() {
      return {
         isRouterAlive: true
      };
   },
   created() {
      this.i18n();
   },
   mounted() {
      document.getElementsByTagName('html')[0].setAttribute('version', process.env.MY_VERSION)
   },
   methods: {
      reload() {
         this.isRouterAlive = false;
         this.$nextTick(() => {
            this.isRouterAlive = true;
         });
      },
      i18n() {
         if (localStorage.i18n) {
            //判断i18n是否存在
            switch (localStorage.i18n) {
               case 'zh':
                  this.$i18n.locale = 'zh';
                  break;
               case 'en':
                  this.$i18n.locale = 'en';
                  break;
               default:
                  return;
                  break;
            }
         }
      },
   },

};
</script>

<style lang="scss" scoped>
#app {
   width: 100%;
   height: auto;
   word-break: break-all;
}
</style>
