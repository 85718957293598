import Vue from "vue";
import VueRouter from "vue-router";

const web = name => () => import(`../views/PC/${name}`);
const Mobile = name => () => import(`../views/Mobile/${name}`);

let flag = navigator.userAgent.match(
   /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);
const both = flag ? Mobile : web;
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err);
};
const routes = [
   {
      path: "/",
      name: "StartPage",
      component: both("StartPage.vue"),
      redirect: "/Home",
      children: [
         {
            path: "/Home",
            name: "Home",
            component: both("Home.vue"),
            meta: {
               title: "中奥智能工业研究院",
               index: 0
            },
         },
         {
            path: "/Product",
            name: "Product",
            component: both("Product/index.vue"),
            meta: {
               title: "中奥智能工业研究院-产品介绍",
               index: 1
            },
         },
         {
            path: "/CoovallyHome",
            name: "CoovallyHome",
            component: both("Product/CoovallyHome.vue"),
            meta: {
               title: "中奥智能工业研究院-Coovally",
               index: 1
            },
         },
         {
            path: "/CooVally",
            name: "CooVally",
            component: both("Product/Coovally.vue"),
            meta: {
               title: "中奥智能工业研究院-CooVally",
               index: 1
            },
         },
         {
            path: "/AIShopNew",
            name: "AIShopNew",
            component: both("Product/AIShopNew.vue"),
            meta: {
               title: "中奥智能工业研究院-AIShop",
               index: 1
            },
         },
         {
            path: "/WeldDefectDetection",
            name: "WeldDefectDetection",
            component: both("Product/SceneDetail/WeldDefectDetection.vue"),
            meta: {
               title: "中奥智能工业研究院-WeldDefectDetection",
               index: 1
            },
         },
         {
            path: "/TargetTracking",
            name: "TargetTracking",
            component: both("Product/SceneDetail/TargetTracking.vue"),
            meta: {
               title: "中奥智能工业研究院-TargetTracking",
               index: 1
            },
         },
         {
            path: "/PostureDetection",
            name: "PostureDetection",
            component: both("Product/SceneDetail/PostureDetection.vue"),
            meta: {
               title: "中奥智能工业研究院-PostureDetection",
               index: 1
            },
         },

         {
            path: "/ImageRestoration",
            name: "ImageRestoration",
            component: both("Product/SceneDetail/ImageRestoration.vue"),
            meta: {
               title: "中奥智能工业研究院-ImageRestoration",
               index: 1
            },
         },
         {
            path: "/SafetyHelmetDetection",
            name: "SafetyHelmetDetection",
            component: both("Product/SceneDetail/SafetyHelmetDetection.vue"),
            meta: {
               title: "中奥智能工业研究院-SafetyHelmetDetection",
               index: 1
            },
         },
         {
            path: "/MaskRecognition",
            name: "MaskRecognition",
            component: both("Product/SceneDetail/MaskRecognition.vue"),
            meta: {
               title: "中奥智能工业研究院-MaskRecognition",
               index: 1
            },
         },
         {
            path: "/GarbageRecognition",
            name: "GarbageRecognition",
            component: both("Product/SceneDetail/GarbageRecognition.vue"),
            meta: {
               title: "中奥智能工业研究院-GarbageRecognition",
               index: 1
            },
         },
         {
            path: "/OCRRecognition",
            name: "OCRRecognition",
            component: both("Product/SceneDetail/OCRRecognition.vue"),
            meta: {
               title: "中奥智能工业研究院-OCRRecognition",
               index: 1
            },
         },
         {
            path: "/TextRecognition",
            name: "TextRecognition",
            component: both("Product/SceneDetail/TextRecognition.vue"),
            meta: {
               title: "中奥智能工业研究院-TextRecognition",
               index: 1
            },
         },
         {
            path: "/LicensePlateRecognition",
            name: "LicensePlateRecognition",
            component: both("Product/SceneDetail/LicensePlateRecognition.vue"),
            meta: {
               title: "中奥智能工业研究院-LicensePlateRecognition",
               index: 1
            },
         },
         {
            path: "/FaceRecognition",
            name: "FaceRecognition",
            component: both("Product/SceneDetail/FaceRecognition.vue"),
            meta: {
               title: "中奥智能工业研究院-FaceRecognition",
               index: 1
            },
         },
         {
            path: "/PCBDefectDetection",
            name: "PCBDefectDetection",
            component: both("Product/SceneDetail/PCBDefectDetection.vue"),
            meta: {
               title: "中奥智能工业研究院-PCBDefectDetection",
               index: 1
            },
         },
         {
            path: "/EleDefectDetection",
            name: "EleDefectDetection",
            component: both("Product/SceneDetail/EleDefectDetection.vue"),
            meta: {
               title: "中奥智能工业研究院-EleDefectDetection",
               index: 1
            },
         },
         {
            path: "/SawBladeDefectDetection",
            name: "SawBladeDefectDetection",
            component: both("Product/SceneDetail/SawBladeDefectDetection.vue"),
            meta: {
               title: "中奥智能工业研究院-SawBladeDefectDetection",
               index: 1
            },
         },
         {
            path: "/ArtificialHeartValvesDefectDetection",
            name: "ArtificialHeartValvesDefectDetection",
            component: both("Product/SceneDetail/ArtificialHeartValvesDefectDetection.vue"),
            meta: {
               title: "中奥智能工业研究院-ArtificialHeartValvesDefectDetection",
               index: 1
            },
         },
         {
            path: "/AutonomousDrivingTargetDetection",
            name: "AutonomousDrivingTargetDetection",
            component: both("Product/SceneDetail/AutonomousDrivingTargetDetection.vue"),
            meta: {
               title: "中奥智能工业研究院-AutonomousDrivingTargetDetection",
               index: 1
            },
         },
         {
            path: "/COVID19LesionsCTDetection",
            name: "COVID19LesionsCTDetection",
            component: both("Product/SceneDetail/COVID19LesionsCTDetection.vue"),
            meta: {
               title: "中奥智能工业研究院-COVID19LesionsCTDetection",
               index: 1
            },
         },
         {
            path: "/HeartLungTracheaCTDetection",
            name: "HeartLungTracheaCTDetection",
            component: both("Product/SceneDetail/HeartLungTracheaCTDetection.vue"),
            meta: {
               title: "中奥智能工业研究院-HeartLungTracheaCTDetection",
               index: 1
            },
         },
         {
            path: "/HumanOrganCTDetection",
            name: "HumanOrganCTDetection",
            component: both("Product/SceneDetail/HumanOrganCTDetection.vue"),
            meta: {
               title: "中奥智能工业研究院-HumanOrganCTDetection",
               index: 1
            },
         },
         {
            path: "/LungSegmentationCTDetection",
            name: "LungSegmentationCTDetection",
            component: both("Product/SceneDetail/LungSegmentationCTDetection.vue"),
            meta: {
               title: "中奥智能工业研究院-LungSegmentationCTDetection",
               index: 1
            },
         },
         {
            path: "/RoadDefectDetection",
            name: "RoadDefectDetection",
            component: both("Product/SceneDetail/RoadDefectDetection.vue"),
            meta: {
               title: "中奥智能工业研究院-RoadDefectDetection",
               index: 1
            },
         },
         {
            path: "/RoadVehicleDetection",
            name: "RoadVehicleDetection",
            component: both("Product/SceneDetail/RoadVehicleDetection.vue"),
            meta: {
               title: "中奥智能工业研究院-RoadVehicleDetection",
               index: 1
            },
         },
         {
            path: "/SolarPanelDefectDetection",
            name: "SolarPanelDefectDetection",
            component: both("Product/SceneDetail/SolarPanelDefectDetection.vue"),
            meta: {
               title: "中奥智能工业研究院-SolarPanelDefectDetection",
               index: 1
            },
         },
         {
            path: "/ApplyTrial",
            name: "ApplyTrial",
            component: both("Product/ApplyTrial.vue"),
            meta: {
               title: "中奥智能工业研究院-ApplyTrial",
               index: 1
            },
         },
         {
            path: "/AIShop",
            name: "AIShop",
            component: both("Product/AISHOP.vue"),
            meta: {
               title: "中奥智能工业研究院-AIShop",
               index: 1
            },
         },
         {
            path: "/POMA",
            name: "POMA",
            component: both("Product/POMA.vue"),
            meta: {
               title: "中奥智能工业研究院-POMA",
               index: 1
            },
         },
         {
            path: "/Slager",
            name: "Slager",
            component: both("Product/Slager.vue"),
            meta: {
               title: "中奥智能工业研究院-Slager",
               index: 1
            },
         },
         {
            path: "/Solution",
            name: "Solution",
            component: both("Solution.vue"),
            meta: {
               title: "中奥智能工业研究院-解决方案",
               index: 2
            },
         },
         {
            path: "/Agendas",
            name: "Agendas",
            component: both("Agendas.vue"),
            meta: {
               title: "中奥智能工业研究院-会议议程",
               index: 3
            },
         },
         {
            path: "/Service",
            name: "Service",
            component: both("Service.vue"),
            meta: {
               title: "中奥智能工业研究院-服务",
               index: 4
            },
         },
         {
            path: "/News",
            name: "News",
            component: both("News.vue"),
            meta: {
               title: "中奥智能工业研究院-新闻动态",
               index: 5
            },
         },
         {
            path: "/NewsPage/:id",
            name: "NewsPage",
            component: both("components/NewsPage.vue"),
            meta: {
               title: "中奥智能工业研究院-新闻详情",
               index: 6
            },
         },
         {
            path: "/About",
            name: "About",
            component: both("About.vue"),
            meta: {
               title: "中奥智能工业研究院-关于我们",
               index: 6
            },
         },
         {
            path: "/POMAPrice",
            name: "POMAPrice",
            component: both("POMAPrice.vue"), meta: {
               index: 1
            },
         },
         {
            path: "/SlagerPrice",
            name: "SlagerPrice",
            component: both("SlagerPrice.vue"), meta: {
               index: 1
            },
         },
      ]
   },
   {
      path: "*",
      redirect: "/",
   },
   {
      path: "/Acticity",
      name: "Acticity",
      component: both("Product/Acticity.vue"),
      meta: {
         title: "中奥智能工业研究院-Acticity",
         index: 1
      },
   },
];

const router = new VueRouter({
   mode: "history",
   routes
});

export default router;
